import React, { useState, useEffect } from "react";
import logo from "../../img/logbranco.png";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { HeaderWrapper, Logo, Nav, HamburgerMenu } from "./Header";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <HeaderWrapper scrolled={scrolled}>
      <Logo src={logo} href="#home" alt="Logo" />

      <HamburgerMenu onClick={toggleMenu} icon={faBars} />
      <Nav isOpen={isOpen}>
        <ul>
          <li>
            <a
              className="link"
              href="#home"
              onClick={(e) => handleScroll(e, "home")}
            >
              Home
            </a>
          </li>
          <li>
            <a
              className="link"
              href="#partido"
              onClick={(e) => handleScroll(e, "partido")}
            >
              Partido
            </a>
          </li>
          <li>
            <a
              className="link"
              href="#candidatos"
              onClick={(e) => handleScroll(e, "candidatos")}
            >
              Representantes
            </a>
          </li>
          <li>
            <a
              className="link"
              href="#ideais"
              onClick={(e) => handleScroll(e, "ideais")}
            >
              Ideais
            </a>
          </li>
          <li>
            <a
              className="link buttonlink"
              href="#faleconosco"
              onClick={(e) => handleScroll(e, "faleconosco")}
            >
              Fale conosco
            </a>
          </li>
        </ul>
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;
