import { secundaryColor, fourtyColor, primaryColor, desctext } from '../../config/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const IdeaisSection = styled.section`
padding-top: 20px;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;




  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const IdeaisText = styled.div`
text-align: center;

`;

export const Title = styled.h1`
    font-size: 2.7rem;
    font-weight: 800;
    color: ${secundaryColor};
    margin-bottom: 1rem;
    margin-top: 2rem;
`;

export const IdeaisContainer = styled.div`
display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Centraliza os elementos no centro */
  gap: 20px; /* Espaçamento entre os cards */
  margin-top: 50px;
  width: 100%;
  text-align: center;
`;

export const IdeaisPodemos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: transparent;
  border-radius: 8px;
  padding: 20px;
  height: 250px;
  width: 300px;
  transition: transform 0.3s ease;
  border: 2px solid ${primaryColor};
  text-align: center; /* Centraliza o texto */


`;

export const IdeaisPodemosGreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: transparent;
  border-radius: 8px;
  padding: 20px;
  height: 250px;
  width: 300px;
  transition: transform 0.3s ease;
  border: 2px solid ${secundaryColor};
  

`;
export const TitleIdeaisPurple = styled.h1`
  font-size: 2.2rem;
  color: ${primaryColor};
  font-weight: 800;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 1.9rem;
  }
`;

export const TextIdeaisPurple = styled.p`
  font-size: 1.1rem;
  color: ${primaryColor};

  font-weight: 300;
  @media (max-width: 768px) {

    font-size: 1rem;
  }
  
`;

export const TitleIdeaisWhite = styled.h1`
  font-size: 2.2rem;
  font-weight: 800;
  margin-top: 10px;
  
  margin-bottom: 20px;
  color: ${secundaryColor};
  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 1.9rem;
  }
`;

export const TextIdeaisWhite = styled.p`
  font-size: 1.1rem;
  color: ${secundaryColor};
  font-weight: 300;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const ButtonEstatuto = styled.button`
background-color: ${secundaryColor};
width: 250px;
height: 50px;
font-size: 20px;
border-radius: 15px;
font-weight: 700;
text-transform: uppercase;
border: none;
color: ${fourtyColor};
cursor: pointer;
margin-top: 40px;
`;
