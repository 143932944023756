import React, { useState,useEffect } from "react";
import {
  ContatoSection,
  ContentWrapper,
  ImageDiv,
  Title,
  Text,
  Overlay,
  LogoFundacao,
  ButtonFundacao,
  // Image,
  FormWrapper,
  Form,
  Input,
  AreaText,
  ButtonForm,
  Icon,
  SvgIcon,
} from "./Contato";
import 'aos/dist/aos.css';
import AOS from 'aos';
import logo from "../../img/logofundacaopodemos.png";

function Contato() {

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [enviado, setEnviado] = useState(false);

  const handleEnviarMensagem = (e) => {
    e.preventDefault();

    // Construir a mensagem para enviar para o WhatsApp
    const mensagemWhatsapp = `Olá!%0A%0A*Nome:* ${nome}%0A*E-mail:* ${email}%0A*Telefone:* ${telefone}%0A*Mensagem:* ${mensagem}`;

    // URL do WhatsApp com o número e a mensagem
    const url = `https://wa.me/5519997011110?text=${mensagemWhatsapp}`;

    // Abrir o link para o WhatsApp
    window.open(url, "_blank");

    // Limpar os campos do formulário
    setNome("");
    setEmail("");
    setTelefone("");
    setMensagem("");

    // Mostrar mensagem de enviado com sucesso por 3 segundos
    setEnviado(true);
    setTimeout(() => {
      setEnviado(false);
    }, 3000);
  };
  useEffect(() => {
    AOS.init({ duration: 1800 });
  }, []);
  return (
    <ContatoSection id="Contato">
      <ContentWrapper>
      <ImageDiv>
      <Overlay />
      <Title>Conheça a Fundação Podemos</Title>
      <Text>A Fundação Podemos oferece cursos gratuitos e focados nos temas de Direito Público, Gestão Pública, Tecnologia, Comunicação e Estratégia Eleitoral.</Text>
      <LogoFundacao src={logo} alt="Logo fundacao" />
      <a href="https://edu.fundacaopodemos.org.br/?_ga=2.212292499.683073098.1691594342-2140293573.1691452844">
        <ButtonFundacao>Conhecer</ButtonFundacao>
      </a>
    </ImageDiv>
    <FormWrapper onSubmit={handleEnviarMensagem}>
      <Form>
        <Input type="text" placeholder="Nome" value={nome} onChange={(e) => setNome(e.target.value)} maxLength={150} required />
        <Input type="email" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={150} required />
        <Input type="tel" placeholder="Telefone" value={telefone} onChange={(e) => setTelefone(e.target.value)} maxLength={11} required />
        <AreaText type="text" placeholder="Mensagem" value={mensagem} onChange={(e) => setMensagem(e.target.value)} maxLength={300} required />
        <ButtonForm type="submit">ENVIAR MENSAGEM</ButtonForm>
        {enviado && <p style={{ color: "green" }}>Mensagem enviada com sucesso!</p>}
            <Icon>
            <a href="https://www.instagram.com/podemos20_campinas/" target="_blank" rel="noopener noreferrer">
              <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  fill-rule="evenodd"
                  d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                  clip-rule="evenodd"
                />
              </SvgIcon>
              </a>
              <a href="https://www.facebook.com/20podemos" target="_blank" rel="noopener noreferrer">
              <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  fill-rule="evenodd"
                  d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
                  clip-rule="evenodd"
                />
              </SvgIcon>
              </a>
              <a href="https://www.youtube.com/@PodemosNacional20" target="_blank" rel="noopener noreferrer">
              <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  fill-rule="evenodd"
                  d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z"
                  clip-rule="evenodd"
                />
              </SvgIcon>
              </a>
            </Icon>
          </Form>
        </FormWrapper>
      </ContentWrapper>
    </ContatoSection>
  );
}

export default Contato;
