import React, { useEffect,useState } from "react";
import {
  CandidatoSection,
  CandidatoText,
  Title,
  CandidatosContainer,
  CandidatoPodemos,
  Imagem,
  Nome,
  ConhecerButton,
  TextDesc,
  Descricao
} from "./Candidatos";
import "aos/dist/aos.css";
import AOS from "aos";
import eduardomagoga from "../../img/Representantes/eduardomagoga.png";
import cirilo from "../../img/Representantes/luizcirilo.png";
import hebertganem from "../../img/Representantes/hebertganem.png";
import adegas from "../../img/Representantes/adegasjunior.png"


const representantes = [
  {
    nome: "Luiz Cirilo",
    imagem: cirilo,
    descricao: "Eleito com 5.694 votos para a Legislatura 2025-2028, Luiz Henrique Cirilo chega ao seu quinto mandato consecutivo na Câmara Municipal de Campinas. Com uma trajetória sólida na política e na advocacia, Cirilo tem um histórico de atuação voltado para meio ambiente, finanças públicas, legalidade e desenvolvimento urbano.\nNascido em Campinas, Cirilo iniciou sua vocação política ainda no período acadêmico, quando presidiu o Diretório Acadêmico de Direito da PUC-Campinas. Formado advogado, teve uma participação ativa na Ordem dos Advogados do Brasil (OAB-Campinas), onde foi vice-presidente entre 2007 e 2009. \nEm abril de 2024, Luiz Cirilo ingressou no Podemos, consolidando sua liderança dentro do partido e sendo reeleito na mesma eleição. Com ampla experiência legislativa, segue comprometido em trabalhar pelo crescimento de Campinas, buscando transparência, desenvolvimento e políticas públicas eficazes para a cidade.",
    instagram: "https://www.instagram.com/luizhcirilo/",
  },
  {
    nome: "Hebert Ganem",
    imagem: hebertganem,
    descricao: "Hebert Ganem foi eleito vereador de Campinas pela primeira vez para a Legislatura 2025-2028, com 5.004 votos, destacando-se como um defensor ativo da proteção animal e das causas sociais. Sua trajetória é marcada por conquistas tanto na esfera estadual quanto municipal, sempre trabalhando por políticas públicas que garantam bem-estar e dignidade para todos.\nAntes de assumir o cargo na Câmara Municipal, Hebert acumulou experiência como assessor na Assembleia Legislativa do Estado de São Paulo (Alesp), atuando ao lado do agora deputado federal Bruno Ganem. Durante esse período, foi peça fundamental na aprovação de leis de grande impacto, como a Lei Anti-Fogos de Estampido, que proibiu fogos barulhentos em todo o estado, protegendo animais, pessoas com deficiência e autistas, que são sensíveis a ruídos intensos.Além disso, ajudou a viabilizar a criação de clínicas veterinárias gratuitas no estado de São Paulo, oferecendo atendimento acessível para famílias em situação de vulnerabilidade.\nHebert Ganem chega à Câmara Municipal de Campinas com o compromisso de fortalecer políticas públicas voltadas à causa animal e ampliar iniciativas de inclusão social. Seu mandato será pautado pela luta por uma cidade mais justa, sustentável e acolhedora, alinhada com ações estaduais que tragam benefícios reais para a população e para os animais.",
    instagram: "https://www.instagram.com/hebertganem/",
  },
  {
    nome: "Eduardo Magoga",
    imagem: eduardomagoga,
    descricao: "Eduardo dos Reis Magoga, conhecido como Du Tapeceiro, foi reeleito para a Legislatura 2025-2028 com 4.780 votos, consolidando seu trabalho e compromisso com a população de Campinas. Este é seu segundo mandato consecutivo, após uma atuação destacada na Legislatura 2021-2024, para a qual foi eleito com 3.856 votos \nEduardo Magoga é comerciante e atua há mais de 25 anos na região norte de Campinas, onde se tornou uma referência em sua comunidade por meio de seu trabalho e envolvimento social.Atualmente, Eduardo Magoga segue atuando ativamente na defesa de pautas essenciais para Campinas, com foco em infraestrutura, segurança, acessibilidade e inclusão social, reafirmando seu compromisso em trabalhar para melhorar a qualidade de vida da população.",
    instagram: "https://www.instagram.com/eduardomagogaoficial/",
  },
  {
    nome: "Adegas Junior",
    imagem: adegas,
    descricao: "Nomeado como Secretario de Clima, Meio Ambiente e Sustentabilidade de Campinas, Adegas Junior é empresario e formado em Gestão Pública.\nCom uma longa carreira na politica, Adegas foi secretário de Meio Ambiente de Santa Bárbara d´Oeste entre 2006 e 2008, Alem disso, foi vereador por dois mandatos na cidade e fez parte do gabinete da deputada federal Renata Abreu",
    instagram: "https://www.instagram.com/eduardomagogaoficial/",
  },
];

function Candidatos() {
  const [expandido, setExpandido] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1800 });
  }, []);


  const handleExpandir = (index) => {
    setExpandido(expandido === index ? null : index);
  };

  return (
    <CandidatoSection>
      <CandidatoText>
        <Title>Representantes</Title>
      </CandidatoText>

      {/* Primeira Linha: 3 Representantes */}
      <CandidatosContainer>
        {representantes.slice(0, 3).map((rep, index) => (
          <CandidatoPodemos key={index}>
            <Imagem src={rep.imagem} alt={rep.nome} />
            <Nome>{rep.nome}</Nome>
            <ConhecerButton onClick={() => handleExpandir(index)}>
              {expandido === index ? "Fechar" : "Conhecer"}
            </ConhecerButton>
            {expandido === index && <Descricao>{rep.descricao}</Descricao>}
          </CandidatoPodemos>
        ))}
      </CandidatosContainer>

      {/* Segunda Linha: 1 Representante */}
      <CandidatosContainer single>
        <CandidatoPodemos>
          <Imagem src={representantes[3].imagem} alt={representantes[3].nome} />
          <Nome>{representantes[3].nome}</Nome>
          <ConhecerButton onClick={() => handleExpandir(3)}>
            {expandido === 3 ? "Fechar" : "Conhecer"}
          </ConhecerButton>
          {expandido === 3 && <Descricao>{representantes[3].descricao}</Descricao>}
        </CandidatoPodemos>
      </CandidatosContainer>

      <TextDesc>Os melhores representantes, para a melhor cidade!</TextDesc>
    </CandidatoSection>
  );
}

export default Candidatos;
