import { secundaryColor, fourtyColor, primaryColor, desctext } from '../../config/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const CandidatoSection = styled.section`
padding-top: 20px;
  width: 100%;
  height: auto;
  background: ${secundaryColor};
  overflow-x: hidden;

  
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const CandidatoText = styled.div`
text-align: center;
`;

export const Title = styled.h1`
    font-size: 2.7rem;
    font-weight: 800;
    color: ${fourtyColor};
    margin-bottom: 1rem;
    margin-top: 2rem;
`;

export const CandidatosContainer = styled.div`
  display: grid;
  grid-template-columns:  ${(props) => (props.single ? "1fr" : "repeat(3, 1fr)")};
  gap: 10px; 
  margin-top: 50px;
  width: 100%;
  text-align: center;
  @media (max-width: 1024px) {
    grid-template-columns: ${(props) => (props.single ? "1fr" : "repeat(2, 1fr)")};
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Uma coluna no mobile */
  }
`;

export const CandidatoPodemos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: transparent;
  border-radius: 8px;
  padding: 10px;
  transition: transform 0.3s ease;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);


`;
export const Imagem = styled.img`
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 400px;
    height: auto;
  }
`;

export const Nome = styled.h2`
  font-size: 1.5rem;
  color: ${fourtyColor};
  margin: 10px 0;
`;

export const ConhecerButton = styled.button`
  width: 150px;
  height: 50px;
  background-color: ${fourtyColor};
  color: ${primaryColor};
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;

  &:hover {
    background-color: ${primaryColor};
    color: ${fourtyColor};
  }
`;

export const Descricao = styled.p`
  font-size: 1rem;
  color: ${fourtyColor};
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
`;

export const TextDesc = styled.h2`
  font-size: 1.3rem;
  color: ${fourtyColor};
  text-align: center;
  margin: 20px 0;
`;