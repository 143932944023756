import React, { useState, useEffect } from "react";
import {
  PartidoSection,
  ContentWrapper,
  TextWrapper,
  Title,
  Text,
  Button,
  Image,
  ImagemDiv,
  Icon,
  SvgIcon,
} from "./Partido";

import fotovereadores from "../../img/partido/vereadores.jpg"
import fotogeral from "../../img/partido/geral.jpg"
import fotoadegas from "../../img/partido/secretario.jpg"
import fototarcisio from "../../img/partido/tarcisio.jpg"
import fotoeduardo from "../../img/partido/magogacomadegas.jpg"

import 'aos/dist/aos.css';
import AOS from 'aos';

function Partido() {
  const images = [fotovereadores, fotoadegas, fotogeral, fototarcisio, fotoeduardo];
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 2200 }); // Ajuste a duração conforme necessário
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <PartidoSection id="partido">
      <ContentWrapper>
        <ImagemDiv>
          <Image src={images[currentSlide]} alt="Sample" />
        </ImagemDiv>
        <TextWrapper>
          <Title>Partido Podemos Campinas:</Title>
          <Text>
          Fundado na década de 90, o Podemos é um dos partidos que mais cresce no Brasil, consolidando-se como uma força política comprometida com ética, inovação e o bem-estar social.

Nas eleições de 2024, o partido alcançou uma votação expressiva de 38.313 votos, um crescimento de 240% em relação a 2020, elegendo três vereadores em Campinas.

Atualmente, o Podemos Campinas conta com os vereadores Luiz Cirilo, Hebert Ganem, Eduardo Magoga e o Secretário Municipal de Clima, Meio Ambiente e Sustentabilidade de Campinas Adegas Junior.
          </Text>
          <Button>
            <a href="https://www.podemos.org.br/historia/" target="_blank">Podemos 20</a>
          </Button>
          <Icon>
          <a href="https://www.instagram.com/podemos20_campinas/" target="_blank" rel="noopener noreferrer">
            <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" href="https://www.instagram.com/podemos20_campinas/" target="_blank">
              <path
                fill-rule="evenodd"
                d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
                clip-rule="evenodd"
              />
            </SvgIcon>
            </a>
            <a href="https://www.facebook.com/20podemos" target="_blank" rel="noopener noreferrer">
            <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill-rule="evenodd"
                d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
                clip-rule="evenodd"
              />
            </SvgIcon>
            </a>
            <a href="https://www.youtube.com/@PodemosNacional20" target="_blank" rel="noopener noreferrer">
            <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                fill-rule="evenodd"
                d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z"
                clip-rule="evenodd"
              />
            </SvgIcon>
            </a>
          </Icon>
        </TextWrapper>
      </ContentWrapper>
    </PartidoSection>
  );
}

export default Partido;
